import { Theme } from "@/shared/constants";

import ActivitiesHeaderLight from "@themedGraphics/light/activities-header.svg?dataUrl";
import ActivitiesHeaderNight from "@themedGraphics/night/activities-header.svg?dataUrl";
import ActivitiesHeaderEndemic from "@themedGraphics/endemic/activities-header.svg?dataUrl";

import ArtistsHeaderLight from "@themedGraphics/light/artists-header.svg?dataUrl";
import ArtistsHeaderNight from "@themedGraphics/night/artists-header.svg?dataUrl";
import ArtistsHeaderEndemic from "@themedGraphics/endemic/artists-header.svg?dataUrl";

import ArtworkHeaderLight from "@themedGraphics/light/artwork-header.svg?dataUrl";
import ArtworkHeaderNight from "@themedGraphics/night/artwork-header.svg?dataUrl";
import ArtworkHeaderEndemic from "@themedGraphics/endemic/artwork-header.svg?dataUrl";

import CollectionsHeaderLight from "@themedGraphics/light/collections-header.svg?dataUrl";
import CollectionsHeaderNight from "@themedGraphics/night/collections-header.svg?dataUrl";
import CollectionsHeaderEndemic from "@themedGraphics/endemic/collections-header.svg?dataUrl";

import CreateHeaderLight from "@themedGraphics/light/create-header.svg?dataUrl";
import CreateHeaderNight from "@themedGraphics/night/create-header.svg?dataUrl";
import CreateHeaderEndemic from "@themedGraphics/endemic/create-header.svg?dataUrl";

import NewsHeaderLight from "@themedGraphics/light/news-header.svg?dataUrl";
import NewsHeaderNight from "@themedGraphics/night/news-header.svg?dataUrl";
import NewsHeaderEndemic from "@themedGraphics/endemic/news-header.svg?dataUrl";

import QuoteImageWrapperLight from "@themedGraphics/light/quote-image-wrapper.svg?dataUrl";
import QuoteImageWrapperNight from "@themedGraphics/night/quote-image-wrapper.svg?dataUrl";
import QuoteImageWrapperEndemic from "@themedGraphics/endemic/quote-image-wrapper.svg?dataUrl";

import RankingHeaderLight from "@themedGraphics/light/ranking-header.svg?dataUrl";
import RankingHeaderNight from "@themedGraphics/night/ranking-header.svg?dataUrl";
import RankingHeaderEndemic from "@themedGraphics/endemic/ranking-header.svg?dataUrl";

import SearchHeaderLight from "@themedGraphics/light/search-header.svg?dataUrl";
import SearchHeaderNight from "@themedGraphics/night/search-header.svg?dataUrl";
import SearchHeaderEndemic from "@themedGraphics/endemic/search-header.svg?dataUrl";

import NotFoundHeaderLight from "@themedGraphics/light/not-found-header.svg?dataUrl";
import NotFoundHeaderNight from "@themedGraphics/night/not-found-header.svg?dataUrl";
import NotFoundHeaderEndemic from "@themedGraphics/endemic/not-found-header.svg?dataUrl";

import ETFHeaderLight from "@themedGraphics/light/etf.svg?dataUrl";
import ETFHeaderNight from "@themedGraphics/night/etf.svg?dataUrl";
import ETFHeaderEndemic from "@themedGraphics/endemic/etf.svg?dataUrl";

import ETFHeroHeaderLight from "@themedGraphics/light/etf-hero.svg?dataUrl";
import ETFHeroHeaderNight from "@themedGraphics/night/etf-hero.svg?dataUrl";
import ETFHeroHeaderEndemic from "@themedGraphics/endemic/etf-hero.svg?dataUrl";

export type GraphicNames =
  | "activities-header"
  | "artists-header"
  | "artwork-header"
  | "collections-header"
  | "create-header"
  | "news-header"
  | "quote-image-wrapper"
  | "search-header"
  | "not-found-header"
  | "error-header"
  | "ranking-header"
  | "search-header"
  | "forum-header"
  | "etf-header"
  | "etf-hero-header";

type ThemedGraphics = {
  [N in GraphicNames]: {
    [T in Theme]: any;
  };
};
// prettier-ignore
export const GRAPHICS: ThemedGraphics = {
  "activities-header": {
    [Theme.Light]: ActivitiesHeaderLight,
    [Theme.Night]: ActivitiesHeaderNight,
    [Theme.Endemic]: ActivitiesHeaderEndemic,
  },
  "artists-header": {
    [Theme.Light]: ArtistsHeaderLight,
    [Theme.Night]: ArtistsHeaderNight,
    [Theme.Endemic]: ArtistsHeaderEndemic,
  },
  "artwork-header": {
    [Theme.Light]: ArtworkHeaderLight,
    [Theme.Night]: ArtworkHeaderNight,
    [Theme.Endemic]: ArtworkHeaderEndemic,
  },
  "collections-header": {
    [Theme.Light]: CollectionsHeaderLight,
    [Theme.Night]: CollectionsHeaderNight,
    [Theme.Endemic]: CollectionsHeaderEndemic,
  },
  "create-header": {
    [Theme.Light]: CreateHeaderLight,
    [Theme.Night]: CreateHeaderNight,
    [Theme.Endemic]: CreateHeaderEndemic,
  },
  "news-header": {
    [Theme.Light]: NewsHeaderLight,
    [Theme.Night]: NewsHeaderNight,
    [Theme.Endemic]: NewsHeaderEndemic,
  },
  "quote-image-wrapper": {
    [Theme.Light]: QuoteImageWrapperLight,
    [Theme.Night]: QuoteImageWrapperNight,
    [Theme.Endemic]: QuoteImageWrapperEndemic,
  },
  "ranking-header": {
    [Theme.Light]: RankingHeaderLight,
    [Theme.Night]: RankingHeaderNight,
    [Theme.Endemic]: RankingHeaderEndemic,
  },
  "search-header": {
    [Theme.Light]: SearchHeaderLight,
    [Theme.Night]: SearchHeaderNight,
    [Theme.Endemic]: SearchHeaderEndemic,
  },
  "not-found-header": {
    [Theme.Light]: NotFoundHeaderLight,
    [Theme.Night]: NotFoundHeaderNight,
    [Theme.Endemic]: NotFoundHeaderEndemic,
  },
  "error-header": {
    [Theme.Light]: NotFoundHeaderLight,
    [Theme.Night]: NotFoundHeaderNight,
    [Theme.Endemic]: NotFoundHeaderEndemic,
  },
  "forum-header": {
    [Theme.Light]: NotFoundHeaderLight,
    [Theme.Night]: NotFoundHeaderNight,
    [Theme.Endemic]: NotFoundHeaderEndemic,
  },
  "etf-header": {
    [Theme.Light]: ETFHeaderLight,
    [Theme.Night]: ETFHeaderNight,
    [Theme.Endemic]: ETFHeaderEndemic,
  },
  "etf-hero-header": {
    [Theme.Light]: ETFHeroHeaderLight,
    [Theme.Night]: ETFHeroHeaderNight,
    [Theme.Endemic]: ETFHeroHeaderEndemic,
  },
};
